const appName = require('../../../package.json').name;
const serviceName = require('../package.json').name;
const featureToggleDev = require('./featureToggle/dev').featureToggle;
const featureToggleProd = require('./featureToggle/prod').featureToggle;

const getEnvironment = () => {
    if (typeof window === 'undefined') {
      return process.env.APP_ENV === 'production' ? 'prod' : 'dev';
    }

    return window.location.origin === 'https://heycast.me' ? 'prod' : 'dev';
};

const fullServiceName = `${appName}-${serviceName}`;
const region = 'ap-southeast-1';

const bucketName = `${appName}-assets`;
const talentMediaBucketName = `${appName}-talent-media`;
const environment = getEnvironment();
const isOffline = process.env.IS_OFFLINE;
const wslOfflineHost = process.env.WSL_OFFLINE_HOST ?? 'localhost';
const offlineHost = process.env.OFFLINE_HOST ?? wslOfflineHost;

const staticDomain =
  isOffline === 'true'
    ? `http://${offlineHost}:8080`
    : `https://${bucketName}-${environment}.s3.${region}.amazonaws.com`;

const lambdaDomain =
  isOffline === 'true'
    ? `http://${offlineHost}:3000`
    : environment === 'dev' ? 'https://dev.heycast.me' : 'https://heycast.me' 

const talentMediaDomain = `https://${talentMediaBucketName}-${environment}.s3.${region}.amazonaws.com`;

const apiDomain = environment === 'prod' ? 'https://api.heycast.me' : 'https://api-dev.heycast.me';
const uiHostDomain = environment === 'prod' ? 'https://heycast.me' : 'https://dev.heycast.me';
const sstApiDomain = environment === 'prod' ? 'https://gateway.heycast.me' : 'https://gateway-dev.heycast.me';
const sstOfflineHost = process.env.SST_OFFLINE_HOST;

const allowedOrigin = staticDomain;

// TODO: Local dev api gateway setup, without having to setup service domain 1 by 1
const serviceDomain = {
  ui: `${isOffline ? `http://${offlineHost}:3000` : uiHostDomain}`,
  analytic: `${isOffline ? `http://${offlineHost}:3010` : `${apiDomain}/info`}`,
  auth: `${isOffline ? `http://${offlineHost}:3020` : `${apiDomain}/auth`}`,
  job: `${isOffline ? `http://${offlineHost}:3030` : `${apiDomain}/job`}`,
  monitor: `${isOffline ? `http://${offlineHost}:3040` : `${apiDomain}/log`}`,
  talent: `${isOffline ? `http://${offlineHost}:3050` : `${apiDomain}/talent`}`,
  settings: `${
    isOffline ? `http://${offlineHost}:3060` : `${apiDomain}/settings`
  }`,
  enquiry: `${isOffline ? sstOfflineHost : sstApiDomain}/enquiry`,
  notification: `${isOffline ? sstOfflineHost : sstApiDomain}/notification`,
  activity: `${isOffline ? sstOfflineHost : sstApiDomain}/activity`,
  location: `${isOffline ? sstOfflineHost : sstApiDomain}/location`,
  fileManage: `${isOffline ? sstOfflineHost : sstApiDomain}/filemanage`,
  sstAuth: `${isOffline ? sstOfflineHost : sstApiDomain}/auth`,
  hirer: `${isOffline ? sstOfflineHost : sstApiDomain}/hirer`,
  rating: `${isOffline ? sstOfflineHost : sstApiDomain}/rating`,
  accounting: `${isOffline ? sstOfflineHost : sstApiDomain}/accounting`,
};

const telegramBotId = environment === 'prod' ? 'heycastme_bot' : 'heycastme_devbot';
const googleTrackingId = 'G-SYLJRC1BV0';
const vapidPublicKey =  process.env.VAPID_PUBLIC_KEY;
const videoThumbnailPlaceholder = 'https://heycastme-talent-media-prod.s3.ap-southeast-1.amazonaws.com/vid-placeholder.png';
const supportEmail = 'hello@heycast.me';

const featureToggle = environment === 'prod' ? featureToggleProd : featureToggleDev;

exports.appName = appName;
exports.serviceName = serviceName;
exports.fullServiceName = fullServiceName;
exports.region = region;
exports.bucketName = bucketName;
exports.staticDomain = staticDomain;
exports.lambdaDomain = lambdaDomain
exports.talentMediaDomain = talentMediaDomain;
exports.serviceDomain = serviceDomain;
exports.isOffline = isOffline;
exports.telegramBotId = telegramBotId;
exports.wslOfflineHost = wslOfflineHost;
exports.offlineHost = offlineHost;
exports.allowedOrigin = allowedOrigin;
exports.googleTrackingId = googleTrackingId;
exports.vapidPublicKey = vapidPublicKey;
exports.videoThumbnailPlaceholder = videoThumbnailPlaceholder;
exports.supportEmail = supportEmail;
exports.featureToggle = featureToggle;
